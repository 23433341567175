import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ProjectCard from "../components/projectCard";

class Projects extends Component {
  constructor(props) {
    super(props);
  }

  redirectToHome = () => {
    const { history } = this.props;
    if (history) history.push("/blog");
  };

  render() {
    return (
      <div className="content-container">
        <div>
          <h1 className="page-title">My Projects</h1>
        </div>
        <br></br>
        <ProjectCard
          url="https://www.alloy.report/"
          title="Alloy.report"
          technologies={["JavaScript", "React", "Firebase"]}
          description="Alloy assists Scrum teams in tracking mood and motivation with intelligent insights."
        ></ProjectCard>
        <ProjectCard
          url="https://www.opevo.io"
          title="Opevo.io"
          technologies={["JavaScript", "Mongo", "Express", "React", "Node"]}
          description="A podcast streaming app with social integration."
        ></ProjectCard>
        <ProjectCard
          url="https://redditdash.herokuapp.com/"
          title="Reddit Dashboard"
          technologies={["JavaScript", "Python", "Flask"]}
          description="Analyse the current trends in a subreddit using this dashboard."
        ></ProjectCard>
        <ProjectCard
          url="https://github.com/AaronWalker96/project-name-generator"
          title="Project Name Generator"
          technologies={["JavaScript", "Golang", "Docker"]}
          description="Generate names for your projects using a word generation service."
        ></ProjectCard>
      </div>
    );
  }
}

export default withRouter(Projects);
