import React, { Component } from "react";
import BlogCard from "../components/blogCard";
import posts from "./../posts/postRegistry.js";

export default class Blog extends Component {
  render() {
    const postsList = posts.map((d) => (
      <BlogCard
        slug={d.file}
        title={d.title}
        date={d.date}
        length={d.length}
        description={d.description}
      ></BlogCard>
    ));

    return (
      <div className="content-container">
        <div className="page-title-container">
          <h1 className="page-title">Blog</h1>
        </div>
        <br></br>
        {postsList}
      </div>
    );
  }
}
