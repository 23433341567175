import React, { Component } from "react";
import ProfileImage from "./../assets/images/profile.jpg";

export default class Home extends Component {
  render() {
    return (
      <div className="content-container" id="home-container">
        <div>
          <h1 className="page-title">Home</h1>
        </div>
        <div className="profile-container">
          <img className="profile-image" src={ProfileImage}></img>
          <div className="profile-intro">
            <h2>Hey, I'm Aaron</h2>
            <p>
              I'm based in Leeds and I currently work as a data engineer, as
              well as building other projects on the side. I enjoy keeping busy
              and learning new stuff.
            </p>
          </div>
        </div>
        <div>
          <h2 className="page-title">Skills</h2>
        </div>
        <br></br>
        <p>
          I've worked as part of teams in agile environments, I have expreience
          with client interaction and writing technical documentation. I've also
          worked with different technologies including:{" "}
          <span
            style={{
              color: "#ca140f",
            }}
          >
            Python
          </span>
          {", "}
          <span
            style={{
              color: "#ca140f",
            }}
          >
            Flask
          </span>
          {", "}
          <span
            style={{
              color: "#ca140f",
            }}
          >
            SQL
          </span>
          {", "}
          <span
            style={{
              color: "#ca140f",
            }}
          >
            HTML
          </span>
          {", "}
          <span
            style={{
              color: "#ca140f",
            }}
          >
            JavaScript
          </span>
          {", "}
          <span
            style={{
              color: "#ca140f",
            }}
          >
            React
          </span>
          {", "}
          <span
            style={{
              color: "#ca140f",
            }}
          >
            Node
          </span>
          {", "}
          <span
            style={{
              color: "#ca140f",
            }}
          >
            Express
          </span>
          {", "}
          <span
            style={{
              color: "#ca140f",
            }}
          >
            Java
          </span>
          {", "}
          <span
            style={{
              color: "#ca140f",
            }}
          >
            Git
          </span>
          {" and "}
          <span
            style={{
              color: "#ca140f",
            }}
          >
            AWS
          </span>
          {"."}
        </p>
        <br></br>
        <div>
          <h2 className="page-title">Education</h2>
        </div>
        <br></br>
        <p>
          <ul>
            <li>
              <span
                style={{
                  color: "#ca140f",
                }}
              >
                University
              </span>{" "}
              (2015 - 2019): BSc Hons Computer Science (Graduated with a 1st
              class degree)
            </li>
            <li>
              <span
                style={{
                  color: "#ca140f",
                }}
              >
                A Levels
              </span>{" "}
              (2013 - 2015): Double Award IT, Graphic Design
            </li>
          </ul>
        </p>
      </div>
    );
  }
}
