import React, { Component } from "react";
import marked from "marked";
import "./../assets/styles/post.css";

export default class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markdown: "",
    };
  }

  componentDidMount() {
    const readmePath = require("./../posts/" + this.props.file + ".md");

    fetch(readmePath)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        this.setState({
          markdown: marked(text),
        });
      });
  }

  render() {
    return (
      <div className="content-container">
        <div className="post-container">
          <div dangerouslySetInnerHTML={{ __html: this.state.markdown }}></div>
        </div>
      </div>
    );
  }
}
