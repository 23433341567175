import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./pages/home";
import Projects from "./pages/projects";
import Blog from "./pages/blog";
import Post from "./pages/post";
import $ from "jquery";
import "./App.css";
import "./assets/styles/page.css";
import "./assets/styles/navbar.css";

class App extends Component {
  render() {
    $(function () {
      $(".item, .toggle")
        .unbind("click")
        .on("click", function () {
          if ($(".item").hasClass("active")) {
            $(".item").removeClass("active");
            $(".toggle").find("a").html("≡");
          } else {
            $(".item").addClass("active");
            $(".toggle").find("a").html("x");
          }
        });
    });

    return (
      <div className="App">
        <Router>
          <nav>
            <ul className="menu">
              <li className="logo">
                <Link to="/">
                  <h1 id="nav-logo">
                    Aaron Walker
                    <span style={{ color: "#CA140F", "font-weight": "900" }}>
                      .
                    </span>
                  </h1>
                </Link>
              </li>

              <li className="item">
                <Link to="/projects">Projects</Link>
              </li>

              <li className="item">
                <Link to="/blog">Blog</Link>
              </li>

              <li className="item">
                <a href="https://github.com/AaronWalker96">GitHub</a>
              </li>

              <li className="item">
                <a href="https://www.linkedin.com/in/aaron-james-walker/">
                  LinkedIn
                </a>
              </li>

              <li className="item button">
                <Link to="/">CV Download</Link>
              </li>

              <li className="toggle">
                <h1>
                  <a>≡</a>
                </h1>
              </li>
            </ul>
          </nav>
          <div className="page-container">
            <Switch>
              <Route exact path="/">
                <Home></Home>
              </Route>
              <Route exact path="/projects">
                <Projects></Projects>
              </Route>
              <Route exact path="/blog">
                <Blog></Blog>
              </Route>
              <Route
                path="/blog/:slug"
                render={({ match }) => {
                  return <Post file={match.params.slug} />;
                }}
              />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
