var posts = [
  {
    title: "Why Software Developes Should Keep Paper Notes",
    date: "5th May 2020",
    length: "10 min read",
    description:
      "Why I'll always keep a pen and paper on me.",
    file: "take-notes",
  },
  {
    title: "Hello, World!",
    date: "16th April 2020",
    length: "15 min read",
    description: "This is a test post",
    file: "hello-world",
  },
];

export default posts;
