import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  background: white;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;
const Title = styled.h2`
  color: #000;
  font-weight: 900;
  padding-bottom: 10px;
`;
const TechnologyContainer = styled.p`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5px;
`;
const Technology = styled.p`
  color: #ca140f;
  border-radius: 5px;
  margin-right: 15px;
  margin-bottom: 5px;
`;
const Description = styled.p`
  color: #000;
  font-family: "Circular Std Book", sans-serif;
`;

const ProjectCard = ({ url, title, technologies, description }) => (
  <Container onClick={() => (window.location = url)}>
    <Title>{title}</Title>
    <TechnologyContainer>
      {technologies.map((tech) => (
        <Technology>{tech}</Technology>
      ))}
    </TechnologyContainer>
    <Description>{description}</Description>
  </Container>
);

export default ProjectCard;
