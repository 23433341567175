import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const Container = styled.div`
  background: white;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;
const Title = styled.h2`
  font-weight: 900;
  padding-bottom: 10px;
`;
const Date = styled.h4`
  color: #ca140f;
  font-family: "Circular Std Book", sans-serif;
  font-weight: 200;
  padding-bottom: 10px;
`;
const Description = styled.p`
  color: #000;
  font-family: "Circular Std Book", sans-serif;
  padding-bottom: 3px;
`;

const BlogCard = ({ title, date, length, description, slug }) => (
  <Link to={"/blog/" + slug}>
    <Container>
      <Title>{title}</Title>
      <Date>
        {date} · {length}
      </Date>
      <Description>{description}</Description>
    </Container>
  </Link>
);

export default BlogCard;
